/**
 * Copyright © 2024 Adnuntius AS.
 */
import angular from 'angular';

import template from './site-copy-modal.html';

import standardActionsModule from '../common/standard-actions-module';
import { actionsHelper } from "../common/controller/actions-helper";
import _ from "lodash";

const MODULE_NAME = 'site-actions-controller';

angular.module(MODULE_NAME, [standardActionsModule])

  .controller('SiteActionsCtrl', function($q, toastr, $stateParams, $state, $uibModal, model, modelComms, readOnly) {
    const ctrl = this;

    actionsHelper(ctrl, model, modelComms, readOnly, $stateParams, $uibModal, $q, toastr, $state, template, {
      url: 'app.sites.site',
      lcase: 'site',
      ucase: 'Site',
      preCopy: function(copyModel) {
        copyModel.iabCategories = _.map(copyModel.iabCategories, function(iab) {
          return _.get(iab, ['id'], iab);
        });
      }
    });
  });

export default MODULE_NAME;