/**
 * Copyright © 2024 Adnuntius AS.
 */
import angular from 'angular';

import twoPanelTemplate from '../common/two-panel.html';
import editTemplate from './site-edit.html';
import analysisTemplate from './site-analysis.html';
import copyTemplate from '../common/copy-actions.html';
import listTemplate from './site-list.html';

import standardModule from '../common/standard-module';
import siteController from './site-controller';
import siteAnalysis from './site-analysis';
import siteActions from './site-actions-controller';
import {ALL_COUNTRIES} from "../common/directives/country-select/countries";
import _ from "lodash";
import {LocalNetworkInfo} from "../../components/session/local-network-info";

const MODULE_NAME = 'site-module';

angular.module(MODULE_NAME, [standardModule, siteController, siteAnalysis, siteActions])

  .config(function($stateProvider, adnRouterHelperProvider) {
    let rh = adnRouterHelperProvider.$get().createRouterAndChildHelper('sites.site', 'Site', 'AdUnit', 'app.inv', 'app', listTemplate);
    $stateProvider.state(rh.listPath(), rh.list({
        resolve: {
          extension: /*@ngInject*/ function () {
            return {
              func: function (ctrl) {
                ctrl.allCountries = _.filter(LocalNetworkInfo.obtain().getInfo('siteCountries'), function(c) {
                  return c.country;
                });

                const cache = {};
                function getCountryName(countries) {
                  _.forEach(countries, function(item) {
                    const foundCountry = cache[item.country] || _.find(ALL_COUNTRIES, function (c) {
                      return c.code === item.country;
                    });
                    if (foundCountry) {
                      item.countryName = foundCountry.name;
                      cache[foundCountry.code] = _.cloneDeep(foundCountry);
                    } else {
                      item.countryName = item.country;
                    }
                  });
                }
                getCountryName(ctrl.allCountries);
                getCountryName(ctrl.items);

                ctrl.getName = function(code) {
                  return cache[code].name;
                };
              }
            };
          },
        }
      }
    ))
      .state(rh.newItemPath(), rh.newItem())
      .state(rh.itemPath(), rh.modelCommsItem({
        views: {
          'content@app': {
            controller: null,
            template: twoPanelTemplate
          },
          'context@app': {
            controller: 'SiteActionsCtrl',
            controllerAs: 'ctrl',
            template: copyTemplate
          },
          'leftPanel@app.sites.site': {
            template: editTemplate,
            controller: 'SiteCtrl',
            controllerAs: 'resource'
          },
          'rightPanel@app.sites.site': {
            template: analysisTemplate,
            controller: 'SiteAnalysisCtrl',
            controllerAs: 'resource'
          }
        }

      }, 'Site', ['EarningsAccount']));
  });

export default MODULE_NAME;